import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faSkype } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faGitlab } from "@fortawesome/free-brands-svg-icons";
import ExternalLink from "./external-link";

const OnlinePresenceField = () => (
  <div className="field is-grouped">
    <p className="control">
      <ExternalLink
        href="https://www.linkedin.com/in/ricardo-garcia-silva/"
        className="has-text-light"
      >
        <FontAwesomeIcon icon={faLinkedin} fixedWidth size="3x" />
      </ExternalLink>
    </p>
    <p className="control">
      <ExternalLink
        href="https://github.com/ricardogsilva"
        className="has-text-light"
      >
        <FontAwesomeIcon icon={faGithub} fixedWidth size="3x" />
      </ExternalLink>
    </p>
    <p className="control">
      <ExternalLink
        href="https://gitlab.com/ricardogsilva"
        className="has-text-light"
      >
        <FontAwesomeIcon icon={faGitlab} fixedWidth size="3x" />
      </ExternalLink>
    </p>
    <p className="control">
      <ExternalLink href="skype:ricgarciasilva?call" className="has-text-light">
        <FontAwesomeIcon icon={faSkype} fixedWidth size="3x" />
      </ExternalLink>
    </p>
    <p className="control">
      <ExternalLink
        href="https://www.facebook.com/ricardo.garcia.silva"
        className="has-text-light"
      >
        <FontAwesomeIcon icon={faFacebook} fixedWidth size="3x" />
      </ExternalLink>
    </p>
  </div>
);

const OnlinePresenceLevel = () => (
  <div className="level">
    <div className="level-left">
      <div className="level-item">
        <ExternalLink
          href="https://www.linkedin.com/in/ricardo-garcia-silva/"
          title="Find me on linkedin"
          className="has-text-light"
        >
          <FontAwesomeIcon icon={faLinkedin} fixedWidth size="3x" />
        </ExternalLink>
      </div>
      <div className="level-item">
        <ExternalLink
          href="https://github.com/ricardogsilva"
          title="Find me on github"
          className="has-text-light"
        >
          <FontAwesomeIcon icon={faGithub} fixedWidth size="3x" />
        </ExternalLink>
      </div>
      <div className="level-item">
        <ExternalLink
          href="https://gitlab.com/ricardogsilva"
          title="Find me on gitlab"
          className="has-text-light"
        >
          <FontAwesomeIcon icon={faGitlab} fixedWidth size="3x" />
        </ExternalLink>
      </div>
      <div className="level-item">
        <ExternalLink
          href="skype:ricgarciasilva?call"
          title="Call me on skype"
          className="has-text-light"
        >
          <FontAwesomeIcon icon={faSkype} fixedWidth size="3x" />
        </ExternalLink>
      </div>
      <div className="level-item">
        <ExternalLink
          href="https://www.facebook.com/ricardo.garcia.silva"
          title="Find me on facebook"
          className="has-text-light"
        >
          <FontAwesomeIcon icon={faFacebook} fixedWidth size="3x" />
        </ExternalLink>
      </div>
    </div>
  </div>
);

export default OnlinePresenceLevel;
