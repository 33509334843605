import React from "react"

const ContactForm = () => (
        <form name="contact" action="/success" method="post" netlify-honeypot="bot-field" data-netlify="true">
          <input type="hidden" name="bot-field" />
          <div className="field">
            <label className="label" htmlFor="contactName">Name</label>
            <div className="control">
              <input id="contactName" name="name" className="input" type="text" placeholder="your name" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="contactEmail">Email</label>
            <div className="control">
              <input
                className="input"
                id="contactEmail"
                name="email"
                type="text"
                placeholder="your email address"
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="contactMessage">Message</label>
            <div className="control">
              <textarea
                className="textarea is-medium"
                id="contactMessage"
                name="message"
                placeholder="Message"
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-primary is-fullwidth">Submit</button>
            </div>
          </div>
        </form>
)

export default ContactForm