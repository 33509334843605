import React from "react"
import PropTypes from "prop-types"

const ExternalLink = props => {
    const { href, children, ...other } = props
    return (
    <a {...other} href={href} target="_blank" rel="noopener noreferrer">{children}</a>
    )
}

ExternalLink.propTypes = {
    href: PropTypes.string,
}

export default ExternalLink