import React from "react";
import { Link } from "gatsby";

const MainNavigation = () => {
  const currentPathName = typeof(window) !== 'undefined' ? window.location.pathname : ''

  function toggleMenu(menuNodeId) {
    const menuNode = document.querySelector(`#${menuNodeId}`);
    menuNode.classList.toggle("is-active");
  }

  return (
    <nav
      className="navbar is-spaced is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            Ricardo Garcia Silva
          </Link>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="mainNavbar"
            onClick={(e) => toggleMenu(e.target.dataset.target)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="mainNavbar" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/portfolio" className={`navbar-item is-tab ${currentPathName === '/portfolio' ? 'is-active' : ''}`}>
              Portfolio
            </Link>
          </div>
          <div className="navbar-end">
            <button
              className="button is-dark-orange"
              onClick={() =>
                window.scroll({
                  top: window.document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
            >
              Contact me
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
