import React from "react";
import ContactForm from "./contact-form";
import OnlinePresence from "./online-presence";

export default ({ author }) => (
  <footer className="footer has-background-black">
    <div className="container has-text-light">
      <div className="level">
        <div className="level-item">
          © {author} {new Date().getFullYear()}
        </div>
      </div>
      <div className="columns">
        <div className="column is-8">
          <h4 className="title is-4">Find me online</h4>
          <OnlinePresence />
        </div>
        <div className="column">
          <h3 className="title is-4">Get in touch</h3>
          <ContactForm />
        </div>
      </div>
    </div>
  </footer>
);
